.App {
  text-align: center;
}

.Art-image {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
  height: 100vmin !important;
}

.Art-image-img-0 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.Art-image-img-1 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.Art-image-img-2 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  transform: scale(2);
}

.Art-image-img-3 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(3);
  -webkit-transform: scale(3);
  transform: scale(3);
}

.Art-image-img-4 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(4);
  -webkit-transform: scale(4);
  transform: scale(4);
}

.Art-image-img-5 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(5);
  -webkit-transform: scale(5);
  transform: scale(5);
}

.Art-image-img-6 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(6);
  -webkit-transform: scale(6);
  transform: scale(6);
}

.Art-image-img-7 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(7);
  -webkit-transform: scale(7);
  transform: scale(7);
}

.Art-image-img-8 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(8);
  -webkit-transform: scale(8);
  transform: scale(8);
}

.Art-image-img-9 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(15);
  -webkit-transform: scale(15);
  transform: scale(15);
}

.Art-image-img-10 {
  height: 100vmin;
  max-width: 100%;
  -moz-transform: scale(20);
  -webkit-transform: scale(20);
  transform: scale(20);
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .Art-image {height: 77vmin !important; margin-bottom: 12px;}
  .Art-image-img-0 {height: 80vmin;}
  .Art-image-img-1 {height: 80vmin;}
  .Art-image-img-2 {height: 80vmin;}
  .Art-image-img-3 {height: 80vmin;}
  .Art-image-img-4 {height: 80vmin;}
  .Art-image-img-5 {height: 80vmin;}
  .Art-image-img-6 {height: 80vmin;}
  .Art-image-img-7 {height: 80vmin;}
  .Art-image-img-8 {height: 80vmin;}
  .Art-image-img-9 {height: 80vmin;}
  .Art-image-img-10 {height: 80vmin;}
  .Art-image-img-11 {height: 80vmin;}
  .Art-image-img-12 {height: 80vmin;}
  .AutoComplete {width: 60%;}
  .Guess { display: flex; justify-content: center;}
}